import * as React from "react"
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Search from "../../../components/SearchContainer";
import PageDetails from "../../../components/PageDetails";
import {useEffect} from "react";

let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "localhost:8000" : process.env.FR_URL) + "/fr/recherche/";
let en_url = (isLocal ? "localhost:8000" : process.env.EN_URL) + "/en/search/";
const lang = process.env.LANG;
const isFrench = lang === "FR";

const SearchPageFr = () => {
  useEffect(() => {
    let windowUrl = new URL(window.location.href);
    let url = new URL(en_url);
    if (windowUrl.searchParams.has("term")) {
      let langLink = document.getElementById("lang_link");
      let href = langLink.getAttribute("href");
      url.searchParams.set("term", windowUrl.searchParams.get("term"));
      langLink.href = url;
    }
  }, []);

  if (!isFrench) {
    return (
      <meta
        http-equiv="refresh"
        content={`0;url=${process.env.FR_URL}/fr/recherche/`}
        id="redirectTag"
      />
    );
  }

  return (
    <>
      <Header
        title={
          "Recherche d’opportunités d’emploi dans nos bureaux du Canada à l’étranger | Affaires Mondiales Canada"
        }
        description={
          "Rechercher et filtrer toutes les opportunités d’emploi dans nos bureaux du Canada à l’étranger."
        }
        lang={"FR"}
        langLink={en_url}
        breadcrumbs={[
          {
            text: "Recherche",
            url: fr_url,
          },
        ]}
        bodyClasses={"page-type-search page-search"}
      />
      <main
        property="mainContentOfPage"
        resource="#wb-main"
        className="container pb-5"
        typeof="WebPageElement"
      >
        <h1>
          Recherche d’opportunités d’emploi dans nos bureaux du Canada à
          l’étranger
        </h1>
        <p>
          Ce que nous faisons compte. Nous définissons, façonnons et faisons
          progresser les intérêts et les valeurs du Canada dans un environnement
          mondial complexe et en pleine évolution. Nous gérons les relations
          diplomatiques, faisons la promotion du commerce international et
          fournissons du soutien consulaire. Nous dirigeons les efforts
          internationaux en matière de développement, d’aide humanitaire et
          d’appui à la paix et à la sécurité. Nous contribuons également à la
          sécurité nationale et au développement du droit international.
        </p>
        <h2>Rechercher les emplois</h2>
        <Search />
        <PageDetails lang={"FR"} dateModified={"2022-01-08"} />
      </main>
      <Footer lang={"FR"} />
    </>
  );
};

export default SearchPageFr;
